import React from "react";
import "./Circle.css"

const Circle = ({fill}) => (
  <div className="circle" style={{backgroundColor: fill ? fill : "white"}}></div>
);

Circle.propTypes = {};

Circle.defaultProps = {};

export default Circle;
