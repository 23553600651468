import React, { useState } from "react";

const Path = () => {
  const [timelineEntries, ] = useState([
    {
      duration: "Seit 2023 Mai",
      role: "Data Scientist",
      location: "Siemens",
    },
    {
      duration: "2022 Oktober bis 2023 April",
      role: "Associate Architect",
      location: "Capgemini",
    },
    {
      duration: "2022 Oktober bis 2023 April",
      role: "Software Engineer",
      location: "Capgemini",
    },
    {
      duration: "Oktober 2017 bis 2020 September",
      role: "M.Sc. Angewandte Informatik",
      location: "Universität Heidelberg",
    },
    {
      duration: "September 2014 bis 2017 September",
      role: "B.Sc. Wirtschaftsinformatik - Software Engineering",
      location: "DHBW Karlsruhe / SAP",
    },
    {
      duration: "2014 Juni",
      role: "Abitur am allgemeinbildenden Gymnasium",
      location: "Singen a. Htwl.",
    },
  ]);

  return (
    <section id="cv" className="section section-2">
      <div className="container">
        <div className="has-text-centered">
          <h3 className="title is-3">My Path</h3>
        </div>
        <div className="timeline">
          {timelineEntries.map((entry, index) => {
            return (
              <div
                className={`timeline-container ${
                  index % 2 === 0 ? "left" : "right"
                }`}
              >
                <div className="timeline-content">
                  <h2>{entry.duration}</h2>
                  <p>{entry.role}</p>
                  <p>{entry.location}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

Path.propTypes = {};

Path.defaultProps = {};

export default Path;
