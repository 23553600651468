import React from "react";

const Projects = () => (
  <section id="projects" className="section section-3">
    <div className="container">
      <div className="has-text-centered">
        <h3 className="title is-3">My Projects</h3>
      </div>

      <div className="columns is-multiline is-desktop">
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img src="img/snacbot.png" alt="SNACBot"></img>
                </figure>
              </div>
              <div>
                <p>
                  Der SNACBot, Smarter NAhverkerhrs ChatBot, entstand im Rahmen
                  eines Hoschulprojekts 2017 an der DHBW Karlsruhe. In
                  natürlicher Sprache können Anfragen zum öffentlichen
                  Nahverkehr, beispielsweise über eine Verbindung, gestellt
                  werden. Diese werden dann mithilfe von Live-Daten beantwortet.
                  Weitere Features sind unter anderem Push-Benachrichtigungen
                  bei Störungen auf Strecken, die der Nutzer bestimmen kann, und
                  das navigieren mithilfe von Shortcuts.
                </p>
              </div>
            </article>
          </div>
        </div>
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img
                    src="img/baretti.PNG"
                    alt="www.robert-baretti-maler.de"
                  ></img>
                </figure>
              </div>
              <div>
                <p>
                  Die Seite von{" "}
                  <a href="http://www.robert-baretti-maler.de">
                    Robert Baretti
                  </a>{" "}
                  entstand 2014, nach meinem Schulabschluss. Auf der Seite sind
                  Bilder des Künstlers und Informationen rund um ihn und seine
                  Werke, sowie Veranstaltungen die ihn betreffen, zu finden.
                </p>
              </div>
            </article>
          </div>
        </div>
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img
                    src="img/ils.PNG"
                    alt="Integrierte Leiststelle Pforzheim-Enzkreis"
                  ></img>
                </figure>
              </div>
              <div>
                <p>
                  Dieses Projekt baut auf der{" "}
                  <a href="https://play.google.com/store/apps/details?id=de.hvo_app.hvo">
                    "Helfer vor Ort"-App
                  </a>{" "}
                  auf. Die{" "}
                  <a href="https://www.hvo-app.de/ils/">Webanwendung</a>
                  dient dazu der Einsatzzentrale eine schnell zu verstehende
                  Übersicht über die eintreffenden Helfer zu geben. Es werden
                  den Benutzern vielseitige Optionen zur Individualisierung der
                  Oberfläche offen gelassen. Um dies zu ermöglichen wurde das
                  Angular-Framework eingesetzt.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div className="columns is-multiline is-desktop">
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img
                    src="img/bauer_jaeger.PNG"
                    alt="Webseite: Jägers Burehöfle"
                  ></img>
                </figure>
              </div>
              <div>
                <p>
                  Die Webseite wurde für einen Bauernhof in Südbaden erstellt.
                  Hierbei kam der (M)EAN-Stack zum Einsatz. Einerseits dient sie
                  Seite als "Digitale Visitenkarte", andererseits auch als
                  Anlaufstelle für Anfragen zu Produkten u.ä. . Die Seite ist
                  nicht rein statisch entwickelt. So lassen sich etwa Bilder und
                  Produkte über eine Administationsseite ändern oder hinzufügen.
                  Die Seite ist unter{" "}
                  <a href="https://jaegers.burehoefle.de/">
                    jaegers.burehoefle.de
                  </a>
                  zu finden.
                </p>
              </div>
            </article>
          </div>
        </div>
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img
                    src="img/chameleon_online.png"
                    alt="Webseite: Chameleon Online"
                  ></img>
                </figure>
              </div>
              <div>
                <p>
                  Die Webanwendung wurde entwickelt, da Besuche an Weihnachten
                  aufgrund der Corona-Situation in unserer Familie ausgefallen
                  sind. Ich wollte ein Gesellschaftsspiel verschenken. Als
                  jedoch klar war, dass wir uns nicht sehen können und somit das
                  Spiel auch nicht gemeinsam spielen werden können, habe ich
                  beschlossen eine Onlineversion zu programmieren. Dabei kommen
                  Angular im Frontend und NestJS im Backend zum Einsatz. Spielen
                  kann man das Ganz mit Freunden unter{" "}
                  <a href="https://chameleon.play-together.app/">
                    chameleon.play-together.app/
                  </a>
                  .
                </p>
              </div>
            </article>
          </div>
        </div>
        <div className="column">
          <div className="box project-text">
            <article>
              <div>
                <figure className="image project-figure">
                  <img
                    src="img/leonie-janowicz.PNG"
                    alt="Webseite: Leonie Janowicz"
                  ></img>
                </figure>
              </div>
              <div>
                <p>
                  Ich habe für meine Freundin (
                  <a href="https://www.leonie-janowicz.de">Leonie Janowicz</a>)
                  und meine Schwester (
                  <a href="https://sophie.desomb.re/">Sophie de Sombre</a>)
                  persönliche Webseiten entwickelt. Die Webseiten basieren auf
                  open-source Templates. Diese wurden von mir angepasst und
                  erweitert.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
);

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
