import React from "react";
import IconLink from "../IconLink/IconLink";

const Header = () => {
  window.onload = () => {
    const navMenu = document.querySelector(".nav-menu");
    const navItems = document.querySelectorAll(".nav-item");
    const hamburger = document.querySelector(".nav-toggle");
    const toggle = (e) => e.classList.toggle("is-active");
    const toggleNav = ({ target }) =>
      Array.from(navMenu.classList).includes("is-active")
        ? toggle(navMenu)
        : null;
    hamburger.addEventListener("click", () => toggle(navMenu, "is-active"));
    Array.from(navItems).forEach((e) => e.addEventListener("click", toggleNav));
  };
  return (
    //<!-- Navbar -->
    <nav className="nav container void-background" style={{ width: "100%" }}>
      <div className="nav-left">
       <IconLink
          href="http://github.com/desombre"
          className="nav-item"
          icon="github"
        ></IconLink>
        <IconLink
          href="https://gitlab.com/desombre"
          className="nav-item"
          icon="gitlab"
        ></IconLink>
        <IconLink
          href="https://twitter.com/desomb_re"
          className="nav-item"
          icon="twitter"
        ></IconLink>
      </div>

      <div className="nav-right nav-menu">
        <a className="nav-item" href="#about">
          About
        </a>
        <a className="nav-item" href="#cv">
          Path
        </a>
        <a className="nav-item" href="#projects">
          Projects
        </a>
        <a className="nav-item" href="#social">
          Social
        </a>
      </div>

      <span className="nav-toggle">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </nav>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
