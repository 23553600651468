import "./App.css";

import {
  Header,
  Profile,
  Path,
  Footer,
  Skills,
  Socials,
  Projects,
} from "./components";

function App() {
  return (
    <>
      <Header></Header>
      <Profile></Profile>
      <Path></Path> <Projects></Projects> <Skills></Skills> <Socials></Socials>{" "}
      <Footer></Footer>
    </>
  );
}

export default App;
