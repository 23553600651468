import React, { useState } from "react";
import "./Profile.css"

const Profile = () => {
  const [clicks, setClicks] = useState(0);
  const faceClick = () => {
    setClicks((clicks) => clicks + 1);
    if (clicks === 8) {
      window.location.href = "/TapNBounce.html";
    }
  };
  return (
    <section id="about" className="section section-1">
      <div className="container has-text-centered">
        <img
          alt="Profil"
          src="./img/profile.jpg"
          id="profile-picture"
          onClick={faceClick}
          className={"avatar animate__animated animate__bounce " + (clicks >= 4 ? "animate__swing" : "")}
        ></img>
      </div>
      <div className="container">
        <p className="intro">
          <b>“Java is to JavaScript what Car is to Carpet.”</b>
          <br />- <i>Chris Heilmann</i>
          <br />
          Mein Name ist Philipp und ich bin momentan Data Scientist bei Siemens
          in Karlsruhe. Ich interessiere mich für Web-Entwicklung, Software
          Engineering, Agile Methodiken und Machine Learning.
        </p>
      </div>
    </section>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
