import React from "react";

const Socials = () => (
  <section id="social" className="section section-3">
    <div className="container">
      <div className="has-text-centered">
        <h3 className="title is-3">Let's Socialize</h3>
      </div>
      <div className="social-container columns">
        <div className="column is-4 has-text-centered">
          <a
            target="_blank"
            href="https://www.xing.com/profile/Philipp_deSombre"
            rel="noreferrer"
          >
            <img className="" alt="Xing" src="img/xing_square.png"></img>
          </a>
        </div>
        <div className="column is-4 has-text-centered">
          <a target="_blank" rel="noreferrer" href="mailto:philipp@desomb.re">
            <img className=""  alt="eMail" src="img/email_square.png"></img>
          </a>
        </div>
        <div className="column is-4 has-text-centered">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/philipp-de-sombre-b04610124/"
          >
            <img className=""  alt="LinkedIn" src="img/linkedin.png"></img>
          </a>
        </div>
      </div>
    </div>
  </section>
);

Socials.propTypes = {};

Socials.defaultProps = {};

export default Socials;
