import React from "react";

const Footer = () => (
  <footer id="footer" className="" style={{ "backgroundColor": "#474e5d" }}>
    <div className="container">
      <div className="columns">
        <div className="column is-6 has-text-centered">
          <a href="#about" style={{ color: "#ffffff" }}>
            Philipp de Sombre
          </a>
        </div>
        <div className="column is-6 has-text-centered">
          <a href="./impressum.html" style={{ color: "#ffffff" }}>
            Impressum
          </a>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
