import React from "react";
import PropTypes from "prop-types";

const IconLink = ({ className, href, icon }) => (
  <a href={href} className={className}>
    <span className="icon">
      <i className={`fa fa-${icon}`}></i>
    </span>
  </a>
);

IconLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
};

IconLink.defaultProps = {};

export default IconLink;
