import React from "react";
import Circle from "../Circle/Circle";
import "./Skills.css"

const Skills = () => {
  const programmingSkills = [
    { name: "Java", level: 7 },
    { name: "TypeScript", level: 8 },
    { name: "JavaScript", level: 7 },
    { name: "NestJS", level: 4 },
    { name: "ReactJS", level: 8 },
    { name: "Python", level: 6 },
    { name: "Angular", level: 4 },
  ];

  const languageSkills = [
    { name: "Deutsch", level: 9 },
    { name: "Englisch", level: 8 },
    { name: "Französisch", level: 3 },
  ];

  return (
    <section id="skills" className="section section-2">
      <div className="container">
        <div className="has-text-centered">
          <h3 className="title is-3">My Skills</h3>
        </div>

        <div className="is-desktop">
          <div className="container">
            <div className="box project-text">
              <article>
                <h2 className="subtitle cloud-text-color">
                  Software Entwicklung
                </h2>

                <div>
                  {programmingSkills
                    .sort((a, b) => b.level - a.level)
                    .map((skill) => (
                      <div className="columns box subbox">
                        <div className="column is-one-fifth skill-name">
                          {skill.name}
                        </div>
                        <div className="column is-one-fifth skill-competence">
                          {[
                            ...new Array(skill.level).fill("#ff9f55"),
                            ...new Array(10 - skill.level).fill("#052d3d"),
                          ].map((fill) => (
                            <div style={{margin: "1px"}}>
                              {" "}
                              <Circle fill={fill}></Circle>{" "}
                            </div>
                          ))}
                        </div>
                        <div className="column skill-description"></div>
                      </div>
                    ))}
                </div>
              </article>
            </div>
          </div>

          <div className="container">
            <div className="box project-text">
              <article>
                <h2 className="subtitle cloud-text-color">Sprachen</h2>
                <div>
                  {languageSkills
                    .sort((a, b) => b.level - a.level)
                    .map((skill) => (
                      <div className="columns box subbox">
                        <div className="column is-one-fifth skill-name">
                          {skill.name}
                        </div>
                        <div className="column is-one-fifth skill-competence">
                        {[
                            ...new Array(skill.level).fill("#ff9f55"),
                            ...new Array(10 - skill.level).fill("#052d3d"),
                          ].map((fill) => (
                            <div style={{margin: "1px"}}>
                              {" "}
                              <Circle fill={fill}></Circle>{" "}
                            </div>
                          ))}
                        </div>
                        <div className="column skill-description"></div>
                      </div>
                    ))}
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Skills.propTypes = {};

Skills.defaultProps = {};

export default Skills;
